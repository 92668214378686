import { Video } from '/features/buildingBlocks/Video'
import { PortableTextMainContent } from '/features/buildingBlocks/PortableText'
import styles from './VideoText.css'

export function VideoText({
  videoUrls = undefined,
  videoUrl = undefined,
  videoTitle = undefined,
  buttonText = undefined,
  blocks
}) {
  return (
    <div className={styles.component}>
      <div className={styles.text}>
        <PortableTextMainContent value={blocks} />
      </div>
      <div className={styles.video}>
        <div className={styles.innerVideo}>
          {videoUrl && (
            <Video {...{ videoUrls, videoUrl, videoTitle, buttonText }} />
          )}
        </div>
      </div>
      <div role='presentation' className={styles.background} />
    </div>
  )
}
